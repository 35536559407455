require('./bootstrap');

import '@lottiefiles/lottie-player';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intlTelInput from 'intl-tel-input';
import sticky from "alpinejs-sticky";

window.Alpine = Alpine;
window.intlTelInput = intlTelInput;

Alpine.plugin(focus);
Alpine.plugin(sticky);
Alpine.start();
